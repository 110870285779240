@import "styles/variables";

.data-table {
  background: #ffffff;
  border: 1px solid #eaedf3;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(216, 216, 216, 0.1);
  padding: 0px;

  .modal_click {
    cursor: pointer;

    &:hover {
      background-color: rgba(215, 229, 252, 0.4);
    }
  }

  @media screen and (max-width: 1199px) {
    overflow-x: scroll;
  }

  @media screen and (max-width: 969px) {
    width: 90vw;
    overflow-x: scroll;
  }

  @media screen and (max-width: 569px) {
    width: 100%;
    // overflow-x: scroll;
  }

  table {
    width: 100%;

    @media screen and (max-width: 769px) {
      width: 768px;
    }

    .table-header {
      background-color: $yara-blue-opacity;
      text-transform: capitalize;
      font-size: 14em / $scale;
      line-height: 1.8em;
      font-weight: 400 !important;
      font-family: "Averia-light", sans-serif;
      text-align: left;

      @media screen and (max-width:1700px) {
        text-align: center;
      }

      line-height: 30px;

      th {
        color: #000000;
        padding: 10em / $scale 26em / $scale;

        &:first-child {
          border-radius: 0.625em 0 0 0;
        }

        &:last-child {
          border-radius: 0 0.625em 0 0;
        }
      }
    }

    .avatar {
      position: relative;
      width: 1.219em;
      // height: 32em / $scale;
      height: inherit;
      border-radius: 6px;
      object-fit: cover;
      margin-left: 25px;
    }

    .table-data-row {
      font-size: 16em / $scale;
      text-align: left;

      td {
        padding: 1.25em 1.625em;
        color: #000000;

        &:first-child {
          // padding-left: 0em;
          padding-right: 0em;
        }

        // &:last-child {
        //     // padding-right: 3.25em;
        // }
      }
    }
  }

  .spinner-border {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: 0.8em solid $yara-blue-opacity;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 1.9s linear infinite spinner-border;
  }
}