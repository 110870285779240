@import "styles/variables";
#settings-material-data {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .dropdown-style {
    right: -10px;
  }

  .edit_tag {
    color: $yara-blue;
  }

  .delete_tag {
    color: #eb5757;
  }
}

.page-title {
  font-family: "Averia-bold";
  font-style: normal;
  font-weight: bold;
  font-size: 18em / $scale;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #0062ff;
}
