@import "styles/variables";
.content {
  h4 {
    font-family: "Averia-bold";
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 969px) {
      font-size: 1.25rem;
    }

    span:nth-child(1) {
      color: $black;
    }
    span:nth-child(2) {
      color: $firebrick;
    }
  }

  p {
    width: 80%;
    text-align: center;
    line-height: 30px;
  }
  .font {
    font-family: "Roboto", sans-serif;
    color: #183884;
    font-weight: 400;
  }

  .w-65 {
    width: 61%;

    @media screen and (max-width: 969px) {
      width: 87%;
    }
  }
  .w-60 {
    width: 58%;

    @media screen and (max-width: 969px) {
      width: 100%;
    }
  }

  .btn-cta-wrapper {
    width: 25%;
    @media screen and (max-width: 969px) {
      width: 100%;
    }
  }

  .cta-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.8125rem;

    @media screen and (max-width: 969px) {
      grid-template-columns: auto;
      gap: 1rem;
    }

    .btn-cta {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: 15px 40px;
      border-radius: 10px;
      background-color: $yara-blue;

      @media screen and (max-width: 969px) {
        font-size: 1.15rem;
      }
    }
  }
}

#coverAct {
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  &.extCov {
    bottom: -80px;
  }
  .circle-background {
    height: 30rem;
    width: 25rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
  }
  img {
    position: absolute;
    left: -5px;
    z-index: 1;
    width: 26rem;
    height: 22rem;
    bottom: 0;
  }
}
