@import "styles/variables";

#dashboard-page {
  .page-title {
    font-family: "Averia-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18em / $scale;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .stats-icon-orange {
    background: $contract;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-leaf {
    background: $community;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-folder {
    background: $folder;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }

  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
    // justify-content: center;
  }

  .form-group {
    display: inline-flex;
  }

  .search-field {
    .search-length {
      width: 140%;

      @media screen and (max-width: 969px) {
        width: 100%;
      }
    }
  }
  .dropdown-style {
    top: -14px;
    right: -10px;
  }

  .activate_tag {
    color: #2f80ed;
  }

  .delete_tag {
    color: #eb5757;
  }

  .disable_tag {
    color: $ash;
  }
}
