@import "styles/variables";

#stats-card {
    .card {
        background: #ffffff;
        border: 1px solid $line;
        border-radius: 0px 10px;

        .icon-image {
        }

        .icon-badge {
            display: flex;
            justify-content: center;
            padding: 20px;
            border-radius: 0px 10px;
        }

        .stats-icon-green {
            background-color: rgba(21, 136, 11, 0.1);
            display: flex;
            justify-content: center;
            padding: 20px;
            border-radius: 0px 10px;
        }

        .stats {
            p {
                &:first-child {
                    font-family: "Averia-bold";
                    font-weight: bold;
                    font-size: 24em / $scale;
                    line-height: 36px;
                    color: $dark-ash;
                    margin-bottom: 10px;
                }

                &:last-child {
                    font-weight: normal;
                    font-size: 15em / $scale;
                    margin-top: 5px;
                    color: $ash;
                }
            }
        }
    }
}
