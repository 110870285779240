@import "styles/variables";

.custom-dropdown-menu {
    cursor: pointer;
    display: inline-block;
    position: relative;

    .menu-box {
        min-width: 200px;
        position: absolute;
        padding: 20px 35px;
        width: max-content;
        z-index: 100;
        background: #ffffff;
        border: 0.5px solid $line;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        // color: #eb5757;
        font-weight: 500;
        // font-family: "GT Walsheim-medium";
        top: 10px;
        left: -190px;
        border: none;
        text-align: left;

        @media screen and (max-width: 768px) {
            left: 10px;
        }
    }

    // .menu-item {
    //     list-style: none;
    //     font-size: 12px;
    //     height: 35px;
    //     padding: 8px 17px;

    //     &:hover {
    //         background-color: rgba(112, 138, 155, 0.05);
    //         cursor: pointer;
    //     }
    // }
}
