.extra_table {
  width: 100%;
  thead {
    background: rgba($color: #0970aa, $alpha: 0.1);

    border-radius: 10px 10px 0px 0px;
    th {
      color: #0970aa;
    }
  }
  tbody {
    background: #ffffff;
    padding: 20px;
  }
  tr {
    padding: 15px;
    td,
    th {
      padding: 15px;
    }
    .discount {
      border: 1px solid #dfe2e6;
    }
  }
}
