@import "styles/variables";
#payment-run {
  .rejection-note {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 1.2rem;
  }
  h6 {
    color: #304ba3;
    font-size: 18px;
  }
  p {
    line-height: 39px;
  }
  .delete-btn {
    background-color: rgb(248, 90, 90);
    width: fit-content;
    border: 0;
    float: right;
  }
  .status {
    // background: rgba(0, 70, 176, 0.15);
    // color: #2f80ed;
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
  .approved {
    background: rgba(0, 176, 80, 0.15);
    color: #00b050;
  }
  .reject {
    background: rgba(248, 90, 90, 0.15);
    color: #f85a5a;
  }
  .posted {
    background: rgba(0, 70, 176, 0.15);
    color: #2f80ed;
  }
  .draft {
    background: rgba(245, 150, 37, 0.15);
    color: #f59625;
  }
  .paid {
    background: #0f630e;
    color: #ffffff;
  }
  .pending {
    background: #efc442;
    color: #2a2a2a;
  }
}
