.budgetContainer{
    background: #F2F2F2;
    border-radius: 12px;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    @media (max-width: 770px) {
       flex-direction: column;
    }
    .left{
        width: 45%;
        @media (max-width: 770px) {
           width: 100%;
         }
        .view{
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            p{
                color: rgba(47, 128, 237, 0.6);
                font-size: 15px;
                
            }
            select{
                background: #FFFFFF;
                border-radius: 5.0315px;
                border: transparent;
                padding: 10px;
                width: 135px;
                margin-left: 7px;
                color: rgba(115, 122, 145, 0.6);
                outline: none;
                font-size: 11px;
            }
        }
        .statusContainer{
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media (max-width:600px) {
               flex-direction: column;
                
              }
            .status{
                .topic{
                    color: rgba(47, 128, 237, 0.6);
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                .checks{
                    .eachCheck{
                        display: flex;
                        align-items: center;
                        margin-bottom: 25px;
                        p{
                            color: #505566;
                            font-size: 15px;
                            margin-left: 15px;
                        }
                    }
                }
            }
            .selectBox{
                .eachSelect{
                    display: flex;
                    align-items: center;
                    margin-bottom: 23px;
                    &:first-child{
                        align-items: unset;
                    }
                    p{
                        color: rgba(47, 128, 237, 0.6);
                        font-size: 15px;
                        width: 71px;
                        font-weight: bold;
                        
                    }
                    select{
                        background: #FFFFFF;
                        border-radius: 5.0315px;
                        border: transparent;
                        padding: 10px;
                        outline: none;
                        width: 135px;
                        margin-left: 10px;
                        color: rgba(115, 122, 145, 0.6);
                        font-size: 11px;
                    }
                }
            }
        }
    }
    .right{
        width: 40%;
        @media (max-width: 770px) {
           width: 100%;
         }
        .dateContainer{
            display: flex;    
            flex-direction: column;
            align-items: flex-end;
            @media (max-width: 770px) {
               align-items: unset;
               
             }
            .dateBox{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                p{
                    color: #737A91;
                    font-size: 14px;
                    font-weight: bold;
                }
                input{
                    background: #FFFFFF;
                    border-radius: 5.0315px;
                    border: transparent;
                    padding:8px 10px;
                    width: 135px;
                    margin-left: 7px;
                    color: rgba(47, 128, 237, 0.6);
                    outline: none;
                    font-size: 12px;
                }
            }
        }
        .chartSide{
            margin-top: 20px;
            .chartOne{
                width: 100%;
                .title{
                    color: rgba(47, 128, 237, 0.6);
                    margin-bottom: 10px;
                    font-weight: bold;
                    font-size: 15px;
                   
                }
                .axis{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    @media (max-width:500px) {
                        flex-direction: column;
                         
                       }
                    .eachAxis{
                        width: 45%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @media (max-width:500px) {
                            width: 100%;
                            margin-bottom: 20px;
                             
                           }
                        
                        p{
                            color: #737A91;
                            font-weight: bold;
                            font-size: 14px;

                        }
                        select{
                            background: #FFFFFF;
                            border-radius: 5.0315px;
                            border: transparent;
                            padding:8px 10px;
                            width: 135px;
                            margin-left: 5px;
                            color: rgba(115, 122, 145, 0.6);
                            outline: none;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}


.filterContainer{
    .titleSide{
        display: flex;
        justify-content: space-between;
        .left{
            display: flex;
            img{
                width: 20px;
            }
            p{
                margin-left: 5px;
                font-size: 18px;
            }
        }
    }

    .filters{
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        justify-content: flex-start;

        .each{
            width: 280px;
            margin-bottom: 10px;
            margin-left: 5px;
            
            @media (max-width: 770px) {
                width: 100%;
             }
           
        }
    }

    .showing{
        padding: 15px 0 15px 20px;
        display: flex;
        margin-top: 15px;
        align-items: center;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        .text{
            font-size: 20px;
            margin-right: 10px;
        }
        .displayDate{
            padding: 8px 25px;
            border-radius: 20px;
            background: rgba(0, 0, 0, 0.2);
          
            p{
                text-align: center;
                font-size: 17px;
                color: black;
            }
        }
    }
    .summary{
        margin-top: 25px;
        .title{
            font-size: 25px;
            margin-bottom: 15px;
            color: rgba(0, 0, 0, 0.2);
        }
        .summaryCover{
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          display: flex;
          .spent{
              width: 33.33%;
              padding: 27px 0;
              display: flex;
              
              align-items: center;
              flex-direction: column;
              &.mid{
                  border-left: 1px solid rgba(0, 0, 0, 0.2);
                  border-right: 1px solid rgba(0, 0, 0, 0.2);
              }
              p.text{
                  font-size: 18px;
              }
              .moneySide{
                  display: flex;
                  margin-bottom: 5px;
                  .currency,.amountMoney{
                      font-size: 35px;
                      font-weight: bold;
                      
                  }

                  .currencyType{
                    //   transform: translateY(6px);
                    margin-top: 5px;
                      font-size: 17px;
                  }
              }
              .deptSide{
                display: flex;
                margin-bottom: 5px;
                .out{
                    font-size: 35px;
                    font-weight: bold;
                }
                .total{
                    // transform: translateY(6px);
                    margin-top: 5px;
                      font-size: 17px;
                }
              }
              .purchase{
                font-size: 35px;
                font-weight: bold;
                margin-bottom: 5px;
              }

          }
        }
    }
}


.chatBox{
   width: 100%;
   margin-top: 20px;
    .title{
        color: #2F80ED;
        font-size: 21px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .chartFlex{
        display: flex;
        // justify-content: space-between;
        width: 100%;
        align-items: center;
        @media (max-width: 770px) {
            flex-direction: column;
         }
         .pieChart{
         
                width: 47%;
                height: 337px;
                margin: 1rem 0;
                @media (max-width: 760px) {
                    width: 100%;
                }
            
         }
         .budgetDetails{
             width: 30%;
             @media (max-width: 760px) {
                width: 100%;
            }
             .topic{
                color: #000000;
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 20px;
             }
             .allDetails{
                 display: flex;
                 flex-wrap: wrap;
                 flex-direction: column;
                 justify-content: space-between;
                 .eachList{
                     display: flex;
                     align-items: center;
                     .circle{
                        background-color: #1F78B4;
                        box-sizing: border-box;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin-right: 3px;
                     }
                     p{
                         font-size: 19px;
                         margin-left:6px ;
                     }
                 }
                 .eachDetail{
                     display: flex;
                     width: 50%;
                     margin-bottom: 20px;
                     .circle{
                        border: 4px solid #1F78B4;
                        box-sizing: border-box;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        transform: translateY(6px);
                        margin-right: 10px;
                        &.green{
                            border: 4px solid #00B01C;
                        }
                        &.bluelight{
                            border: 4px solid #A6CEE3;
                        }
                     }
                     .texts{
                         .name{
                            color: #000000;
                            font-size: 18px;
                            font-weight: bold;
                            margin-bottom: 5px;
                         }
                         .amount{
                            color: #737A91;
                            font-size: 16px;
                         }
                     }
                 }
             }
         }
    }
}


.numb{
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 5px;
    outline: none;
    width: 100%;
    
}