.textarea {
  background: #ffffff;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 10px;
  resize: none;
  padding: 15px 20px;
  max-width: 100%;
  outline: none;
  width: 100%;

  &:focus-within {
    border-color: #8d8bdd;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
  }
}
