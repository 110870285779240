#singleinvoice-page_ {
  .cover_red {
    .css-i4bv87-MuiSvgIcon-root {
      fill: red !important;
      font-size: 22px;
    }
  }
  .cover_person {
    position: relative;
    p {
      position: absolute;
      color: red;
      font-size: 10px;
      left: 15px;
      top: -1px;
      font-weight: 600;
    }
  }

  .container {
    div.spinner-border {
      display: inline-block;
      width: 5rem;
      height: 5rem;
      vertical-align: text-bottom;
      border: 0.8em solid rgba(47, 128, 237, 0.2);
      border-right-color: transparent;
      border-radius: 50%;
      animation: 1.9s linear infinite spinner-border;
    }
    .header-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;

      #download {
        border: 1px solid #e8e8e8;
        padding: 5px 17px;
        border-radius: 0.6rem;
        color: #737a91;
        margin-left: auto;
      }

      .not-saved {
        background: #dfe2e6;
        color: #737a91;
      }

      .payment-status {
        background: #9cffc4;
        color: #3b5c14;
      }

      .payment-status,
      .not-saved {
        margin-left: 3rem;
        padding: 7px 19px;
        border-radius: 0.6rem;
      }

      .title {
        font-weight: 600;
      }
    }

    .main-container {
      display: flex;
      margin-top: 3rem;
      column-gap: 5rem;

      .first-col {
        width: 65%;
        border: 1px solid #e8e8e8;
        > div {
          padding: 10px 8px 10px 4px;
        }

        .first-section {
          display: flex;

          .first-section__first-col {
            margin-right: auto;

            .tag {
              font-weight: 700;
              font-size: 1.1rem;
            }

            .id-number {
              font-weight: 500;
              margin-top: 0.1rem;
              margin-bottom: 0.7rem;
            }
          }
        }

        .second-section {
          margin-top: 2rem;
          display: flex;

          .second-section__first-col {
            margin-right: 4rem;
          }

          .not-saved-first-second-col {
            background: #d0e3e8;
          }

          .second-section__first-col,
          .second-section__second-col {
            border: 1px solid #e8e8e8;
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;

            .address-header {
              background: #e8f1fb;
              color: #737a91;
              padding: 0.2rem 0.5rem;
              font-size: 0.9rem;
            }

            .address-details {
              padding: 0.2rem 0.5rem;
              font-size: 12px;

              .industry-name {
                margin-bottom: 0.7rem;
              }

              .industry-name,
              .address,
              .phone-number {
                line-height: 35px;
              }
            }
          }
        }

        .third-section {
          margin-top: 2rem;
          border-bottom-left-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;

          .third-section__first-col {
            border: 1px solid #e8e8e8;
            width: 44.5%;
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
          }

          .list-header {
            background: #e8f1fb;
            color: #737a91;
            padding: 0.2rem 0.5rem;
            font-size: 0.9rem;
          }

          .terms-details {
            padding: 0.2rem 0.5rem;
            font-size: 12px;

            .terms-date,
            .ref-number {
              line-height: 35px;
            }
          }
        }

        .purchase-table {
          display: flex;
          flex-direction: column;
          border: 1px solid #e8e8e8;
          box-shadow: 0px 1px 5px rgba(216, 216, 216, 0.1);
          border-radius: 0 0 15px 15px;
          padding-bottom: 1rem;

          .table-header {
            th {
              margin-bottom: 3rem;
              padding: 0 1em;
              padding-bottom: 7px;
              text-align: left;
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
            }
          }

          .table-data-row {
            background: #ffffff;
            border: 1px solid #dfe2e6;

            td {
              padding: 0.5em 1em;
              text-align: left;
              font-weight: normal;
              font-size: 12px;
              line-height: 30px;
            }
          }
          // .table-data-row :hover{
          //   background-color: ;
          // }

          .total-tax {
            display: flex;
            margin-left: auto;
            margin-top: 1rem;

            .tax-total {
              margin-right: 1rem;
              padding-top: 0.1rem;
              border-top: solid 20px transparent;
            }

            .prices {
              border: 1px solid #e8e8e8;
              padding: 0.1rem 2.5rem 0.6rem;
              border-radius: 0.6rem;
              border-top: solid 20px rgba(119, 157, 207, 0.1);
            }

            .tax-total,
            .prices {
              display: flex;
              flex-direction: column;
            }

            .tax-total > span {
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
            }

            .prices > span {
              font-weight: normal;
              font-size: 14px;
              line-height: 30px;
              text-align: right;
            }
          }
        }

        .last-section {
          margin-top: 2rem;
          padding: 1rem 0rem;

          .notes-para {
            font-weight: normal;
            font-size: 14px;
            line-height: 30px;
            width: 85%;
            margin-top: 0.7rem;
          }
          .payment {
            padding: 1rem;
            border-bottom: 1px solid #e8e8e8;
            box-shadow: 0px 1px 5px rgba(216, 216, 216, 0.1);
            border-radius: 0 0 15px 15px;
            p {
              // font-size: 18px;
              span {
                // font-size: 14px;
                color: #2f80ed;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }

      .second-col {
        width: 35%;
        display: flex;
        flex-direction: column;

        .comment-container {
          border: 1px solid #dfe2e6;
          border-bottom-left-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }

        .comments {
          border: 0px;
          border-bottom: 1px solid #dfe2e6;
          padding: 2px 10px;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          color: #737a91;
          resize: none;
          width: 100%;
        }

        .comments:focus {
          border: 1px solid #007bff;
          outline: none;
          transition: ease-in 500ms;
        }

        .invoice-status {
          margin-top: 10rem;
          margin-bottom: 6rem;

          .status-flow {
            position: relative;
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 1rem;

              .gray-circle {
                position: relative;
                width: 30px;
                height: 30px;
                border: 1px solid #454545;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
              }
              .red-circle {
                border-color: red !important;
              }

              .green-circle {
                position: relative;
                width: 30px;
                height: 30px;
                border: 1px solid #22a238;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                .rigid-circle {
                  background-color: #22a238;
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  z-index: 1;
                }

                .pipe {
                  position: absolute;
                  bottom: 0px;
                  width: 3px;
                  height: 20px;
                  background-color: #22a238;
                }

                [alt="green-verified"] {
                  margin-left: 0.3rem;
                }

                .upward {
                  position: absolute;
                  top: -20px;
                  width: 3px;
                  height: 19.9px;
                  background-color: #22a238;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 789px) {
  #singleinvoice-page {
    margin-left: -20px;
    margin-right: -20px;

    .container {
      .header-container {
        width: 100%;
        justify-content: unset;
        align-items: unset;

        @media (max-width: 550px) {
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: unset;
          align-items: unset;

          #download {
            margin-left: unset;
            width: fit-content;
            padding: 7px 52px;
          }

          .payment-status,
          .not-saved {
            margin-left: 0px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            width: fit-content;
          }
        }
      }

      .main-container {
        flex-direction: column;
        row-gap: 2rem;

        .first-col {
          width: 100%;

          .second-section {
            flex-wrap: wrap;
            row-gap: 2rem;
          }

          .purchase-table {
            overflow-x: auto;
          }
        }

        .second-col {
          width: 100%;

          .comment-container {
            .comments {
              width: 100%;
            }
          }

          .invoice-status {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
.error {
  text-align: left;
  font-size: 13px;
  color: #d34242 !important;
}

.border-container {
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  padding: 5px;
}

.status {
  // background: rgba(0, 70, 176, 0.15);
  // color: #2f80ed;
  padding: 6px 35px;
  border-radius: 8px;
  text-align: center;
  width: fit-content;
}
.approved {
  background: rgba(0, 176, 80, 0.15);
  color: #00b050;
}
.reject {
  background: rgba(248, 90, 90, 0.15);
  color: #f85a5a;
}
.posted {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}
.draft {
  background: rgba(245, 150, 37, 0.15);
  color: #f59625;
}
.paid {
  background: #0f630e;
  color: #ffffff;
}
.pending {
  background: #efc442;
  color: #2a2a2a;
}
