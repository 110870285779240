@import "styles/variables";

.user-menu {
    // max-width: 100%;
    // overflow: hidden;

    &__content {
        // padding: 1rem 2rem;
        // background: #fdfdfd;
        // box-sizing: border-box;

        & header {
            // display: flex;
            // flex-direction: column;
            // border-bottom: 1px solid #e8e8e8;
            // width: 70%;

            h3 {
                font-size: 17px;
                margin-right: 2rem;
            }

            & div {
                margin-top: 1rem;
                background-color: #2f80ed;
                // width: 2.6rem;
                // height: 3px;
            }
        }

        .stats-icon-orange {
            background: #bc6bd934;
            border-radius: 0px 10px;
            padding: 20px;

            .icon-image {
                color: white;
            }
        }

        .middle-img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            .icon-image {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 6px;
            }
            h5 {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 30px;
            }
        }

        & .document {
            // width: 95%;

            h3 {
                font-size: 18px;
            }
        }
    }





    .container {
        margin-right: 0px;
        margin-left: 0px;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .page-title {
        font-family: "Averia-bold";
        font-style: normal;
        font-weight: bold;
        font-size: 18em / $scale;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .dropdown-style {
        right: -10px;
    }

    .activate_tag {
        color: $green;
    }

    .delete_tag {
        color: #eb5757;
    }

    .disable_tag {
        color: $ash;
    }

    .search-field {
        display: flex;
        @media (max-width: 576px) {
            display: block;
        }
    }

    .stats-icon {
        background: rgba(187, 107, 217, 0.1);
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: #ff7501;
        }
    }
    .stats-icon-leaf {
        background: $leaf;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: white;
        }
    }

    .button-position {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 8px 16px;
    }
    .button-container {
        display: flex;
        // justify-content: center;
    }
    .middle-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        .icon-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
        }
    }

}

  .user-menu__content > header > h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #2F80ED;
  width: fit-content;
  margin-bottom: 0px;
  border-bottom: 3px solid #2F80ED;
  padding-bottom: 1rem;
}

.user-menu__content > header > hr {
  margin-top: 0rem;
}