@import "styles/variables";
.bid-technical-requirment-page {
  .question-box {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    .question-label {
      font-family: "Averia-regular";
      font-size: 18px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #8d8bdd;
      position: relative;
      margin-bottom: 0.7rem;

      &::after {
        display: inline-block;
        content: "" "";
        width: 100%;
        height: 0.25em;
        position: absolute;
        bottom: -0.2em;
        left: 0;
        border-bottom: 1px solid $line;
      }
    }

    .textarea {
      background: #ffffff;
      border: 1px solid #dfe2e6;
      box-sizing: border-box;
      border-radius: 10px;
      resize: none;
      padding: 15px 20px;
      width: 70%;
      outline: none;
      margin-left: 0.9rem;

      &:focus-within {
        border-color: #8d8bdd;
      }

      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }

    .radio-box {
      gap: 0.5rem;
      margin-left: 0.9rem;

      &_label {
        color: $black;
      }
    }

    .upload-box {
      margin-left: 0.9rem;
      display: flex;
      width: 70%;

      gap: 1rem;
      align-items: center;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
      &_label {
        color: $black;
      }

      .fileuploader {
        width: 100%;
      }
    }
  }
  .select {
    width: 70%;
  }
}
