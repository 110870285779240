@import "styles/variables";
.logoWrapper {
    &_logo {
        width: 148px;
        height: 103px;
        margin-bottom: 1rem;

        &_small {
            width: 5rem;
            margin-bottom: 0.2rem;
        }
    }

    &_banner {
        background: $yara-red;
        border-radius: 10px;
        width: 202px;
        font-family: "Candal";

        p {
            font-size: 1.1rem;
            line-height: 2.25rem;
            color: #fff;
        }

        &_small {
            width: 100px;
            background: $yara-red;
            border-radius: 10px;
            font-family: "Candal";

            & > p {
                font-size: 0.625rem;
                color: #fff;
            }
        }
    }
}
