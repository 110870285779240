@import "styles/variables";

#buyer-roles-page {
    .container {
        margin-right: 0px;
        margin-left: 0px;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .page-title {
        font-family: "Averia-bold";
        font-style: normal;
        font-weight: bold;
        font-size: 18em / $scale;
        line-height: 36px;
        letter-spacing: -0.02em;
    }
    .search-field {
        display: flex;
        @media (max-width: 576px) {
            display: block;
        }
    }

    .stats-icon {
        background: rgba(187, 107, 217, 0.1);
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: #ff7501;
        }
    }
    .stats-icon-cash {
        background: $cash;
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
            color: #ff7501;
        }
    }

    .button-position {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 8px 16px;
    }
    .button-container {
        display: flex;
        // justify-content: center;
    }
    .edit {
        border-right: 1px solid #737a91;
        padding-right: 10px;
        width: fit-content;
        color: #737a91;
        cursor: pointer;
    }
    .delete {
        padding-right: 10px;
        width: fit-content;
        color: #eb5757;
        cursor: pointer;
    }
    .span-container {
        display: flex;
        // justify-content: center;
    }
    .middle-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        .icon-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
        }
    }
}
