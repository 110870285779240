@import "styles/variables";

.side-bar {
    min-width: 220px;
    max-width: 220px;
    position: sticky;
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 100;
    transition: left 0.4s;

    @media screen and (max-width: 969px) {
        width: auto;
        position: fixed;
        left: -300px;
        height: 100%;
        background: #b5d4ff;

        &.hide-left {
            left: -300px;
        }

        &.show-left {
            left: 0;
        }
    }
}

.side-bar-navigation {
    position: fixed;
    width: 198px;

    .mbtn-auto {
        margin: 0 20px;
    }

    @media screen and (max-width: 969px) {
        position: relative;
        width: 100%;
    }

    .brand-logo {
        // margin-top: 10px;
        @media screen and (max-width: 969px) {
            display: flex;
            justify-content: space-evenly;
        }

        .close-menu {
            display: none;
            @media screen and (max-width: 969px) {
                display: block;
            }
        }
    }

    .nav-link {
        position: relative;

        &.active {
            background-color: $yara-blue-opacity;
            border-radius: 5px;
            // padding: 0.7rem 1.5rem;
            color: $yara-blue;
            // font-weight: 500;
            transition: all ease-out;
            // font-family: "Averia-bold";

            @media screen and (max-width: 969px) {
                border-radius: 0px;
                color: $yara-blue !important;
            }

            & svg {
                fill: $yara-blue !important;
                & path {
                    fill: $yara-blue !important;
                    // stroke: $yara-blue !important;
                }
            }
        }

        .route-name {
            font-style: normal;
            font-weight: normal;
            font-size: 18em / $scale;
            line-height: 36px;
            letter-spacing: -0.05em;
        }
    }
}
