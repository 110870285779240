@import "styles/variables";
.approve-requisition {
  .container {
    @media screen and (max-width: 769px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  .tab-container {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 0.625rem;
    min-height: 39rem;
    padding-left: 2rem;
    padding-right: 5rem;

    .tabs-page {
      margin-top: -1.5rem;
    }

    @media screen and (max-width: 769px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
  }

  .page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;
    font-family: "Averia-bold";
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    td {
      text-align: center;
      padding: 5px;
      border-bottom: solid 1px #dfe2e6;
      border-top: solid 1px #dfe2e6;
    }
    th {
      padding: 5px;
      text-align: center;
      color: #3d59b7;
      background-color: rgba(119, 157, 207, 0.1);
      line-height: 36px;
      font-weight: 700;
      font-family: "Averia-bold";
    }
    th:first-child {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    th:last-child {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
    }
    .first-td {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    .last-td {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
      color: inherit;
    }
    // tr {
    //   .border-container {
    //     border-bottom: solid 1px red;
    //     border-left: solid 1px red;
    //     border-radius: 5px 0px 0px 5px;
    //     border-top: solid 1px red;
    //   }
    // }
  }
  table tr:first-child {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0px;
  }
  .total-tax {
    display: flex;
    margin-left: auto;
    margin-top: 1rem;

    .tax-total {
      margin-right: 1rem;
      padding-top: 0.7rem;
      border-top: solid 20px transparent;
    }

    .prices {
      border: 1px solid #e8e8e8;
      padding: 0.6rem 2.5rem;
      border-radius: 0.6rem;
      border-top: solid 20px rgba(119, 157, 207, 0.1);
    }

    .tax-total,
    .prices {
      display: flex;
      flex-direction: column;
    }

    .tax-total > span {
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
    }

    .prices > span {
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-align: right;
    }
  }

  textarea {
    border: 1.32424px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 13.2424px;
    width: 100%;
    padding: 10px;
    &::placeholder {
      padding-top: 3rem;
      padding-left: 1rem;
      color: #828282;
    }
    &:focus {
      outline: none;
    }
  }
}

.smally {
  .css-2b097c-container {
    width: 250px;
  }
}

.special_inp {
  padding: 4px 8px;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 250px;
}

.cover_go {
  height: 35.95px;

  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    padding: 0 10px;
    border: none;
    outline: none;
    background: none;
    height: 100%;
    flex: auto;
  }
  button {
    background-color: #3d59b7;
    cursor: pointer;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
