@import "styles/variables";

.buyer-auth-layout {
    height: 100vh !important;
    .row.r-100 {
        height: 100% !important;
        position: relative;
    }
    .left-col {
        background-color: $lavender;
        padding: 1.575rem 2.188rem 0;
        overflow: hidden;
        height: 100%;
        width: 24.3rem;

        h6 {
            font-family: "GT Walsheim-bold";
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 36px;
            color: $ash;
        }
        .circle-background {
            background: $white;
            height: 30rem;
            width: 30rem;
            position: relative;
            bottom: -13rem;
            right: 10rem;
            clip-path: circle(50% at 50% 50%);

            @media (min-width: 1920px) {
                bottom: -20rem;
                right: 10.3rem;
            }
        }
        .bottom-img {
            position: absolute;
            left: -5px;
            z-index: 1;
            width: 26rem;
            bottom: 0;
        }
    }
    .right-col {
        padding-top: 8.8rem;
        @media (max-width: 576px) {
            padding: 3.8rem 1.7rem;
        }
        @media (max-width: 1199.98px) {
            .justify {
                justify-content: center;
            }
        }

        .right-content {
            width: 23rem;
        }

        h3 {
            font-family: "Averia-bold";
            font-size: 24px;
            font-style: normal;
            font-weight: bold;
            line-height: 36px;
            letter-spacing: -0.05em;
            text-align: left;
            color: $black;

            & > .link {
                color: #340e73;
            }
        }
        .p-link {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
            color: $black;
        }
        .link {
            color: $yara-blue;
        }
        .support {
            position: absolute;
            top: 38rem;
        }
        .p-content {
            font-family: "Averia-regular";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
            display: flex;
            align-items: center;
            color: $ash;
            width: 90%;
        }
    }
    @media (min-width: 1199.98px) {
        .right-content {
            position: relative;
            width: 21rem;
        }
    }
    @media (max-width: 1199.98px) {
        .display {
            display: none;
        }
    }
}
