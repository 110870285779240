@import "styles/variables";

.supplier-registration {
    height: 100vh !important;

    .row.r-100 {
        height: 100% !important;
        position: relative;
    }
    .left-col {
        background-color: $lavender;
        padding: 1.575rem 2.188rem 0;
        overflow: hidden;
        height: 100%;
        width: 24.3rem;
        // position: sticky;

        h6 {
            font-family: "GT Walsheim-bold";
            font-style: normal;
            font-weight: bold;
            font-size: 23px;
            line-height: 36px;
            color: $ash;
        }
        .circle-background {
            background: $white;
            height: 30rem;
            width: 30rem;
            position: relative;
            bottom: -13rem;
            right: 10rem;
            clip-path: circle(50% at 50% 50%);

            @media (min-width: 1920px) {
                bottom: -20rem;
                right: 10.3rem;
            }
        }
        .bottom-img {
            position: absolute;
            left: -5px;
            z-index: 1;
            width: 26rem;
            bottom: 0;
        }

        .logoWrapper {
            &_logo {
                width: 148px;
                height: 103px;
            }

            &_banner {
                background: $yara-red;
                border-radius: 10px;
                width: 202px;
                font-family: "Candal";

                p {
                    font-size: 1.1rem;
                    line-height: 2.25rem;
                    color: #fff;
                }
            }
        }
    }
    .right-col {
        margin-left: 1.4rem;

        @media screen and (max-width: 969px) {
            margin-left: 0;
        }
    }
    @media (min-width: 1199.98px) {
        .right-content {
            position: relative;
            width: 21rem;
        }
    }
    @media (max-width: 1199.98px) {
        .display {
            display: none;
        }
    }
}
