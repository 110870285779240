@import "styles/variables";

.supplier-contact-information {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    @media screen and (max-width: 969px) {
      grid-template-columns: auto;
    }
  }
  h4 {
    font-size: 23px;
    line-height: 36px;
    letter-spacing: -0.05em;
    font-weight: 400;
    color: #7e7373;

    .link-back {
      color: $ash;
    }
  }

  .w-7 {
    width: 8.125rem;
  }
  .w-5 {
    width: 5.125rem;
  }

  .form-container {
    background: #fdfdfd;
    border: 1px solid $line;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 0px 0px;
    padding: 15px;
    min-height: 90vh;

    .temp_no {
      position: relative;
      padding-bottom: 1.5rem;
      @media screen and (max-width: 969px) {
        font-size: 1.1rem;
      }
      &::after {
        display: inline-block;
        content: "" "";
        width: 100%;
        height: 0.25em;
        position: absolute;
        bottom: 0.2em;
        left: 0;
        border-bottom: 1px solid $line;
      }
    }

    .form-header {
      position: relative;
      font-size: 1.125rem;
      line-height: 2.25rem;
      letter-spacing: -0.02em;
      font-weight: bold;

      &::before {
        display: inline-block;
        content: "" "";
        width: 5%;
        margin-bottom: 0.3rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        border-bottom: 1px solid $line;
      }
      &::after {
        display: inline-block;
        content: "" "";
        width: 27%;
        margin-bottom: 0.3rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        border-bottom: 1px solid $line;
      }
    }
  }
}

#movetxt {
  position: relative;
  -webkit-animation: moving 5s ease-out;
  animation: moving 5s ease-out;
  opacity: 1 !important;
}

@-webkit-keyframes moving {
  from {
    top: 15px;
  }
  to {
    top: 0px;
  }
}

@keyframes moving {
  //   from {
  //     top: 0px;
  //   }
  //   to {
  //     top: 15px;
  //   }
  from {
    top: 15px;
  }
  to {
    top: 0px;
  }
}
