@import "styles/variables";
.approve-requisition {
  .container {
    @media screen and (max-width: 769px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  .pad {
    @media screen and (min-width: 769px) {
      padding: 0 5rem;
    }
  }

  .tab-container {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 0.625rem;
    min-height: 39rem;
    padding-left: 2rem;
    padding-right: 5rem;

    .tabs-page {
      margin-top: -1.5rem;
    }

    @media screen and (max-width: 769px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
  }

  .page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;
    font-family: "Averia-bold";
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    td {
      text-align: center;
      padding: 5px;
      border-bottom: solid 1px #dfe2e6;
      border-top: solid 1px #dfe2e6;
    }
    th {
      padding: 5px;
      text-align: center;
      color: #3d59b7;
      background-color: rgba(119, 157, 207, 0.1);
      line-height: 36px;
      font-weight: 700;
      font-family: "Averia-bold";
    }
    th:first-child {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    th:last-child {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
    }
    .first-td {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    .last-td {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
      color: red;
    }
    // tr {
    //   .border-container {
    //     border-bottom: solid 1px red;
    //     border-left: solid 1px red;
    //     border-radius: 5px 0px 0px 5px;
    //     border-top: solid 1px red;
    //   }
    // }
  }
  table tr:first-child {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0px;
  }
}
