#report {
    .container {
        .header {
            font-weight: bold;
            font-size: 23px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #304ba3;
        }

       & .stats-card {
            // border: 4px solid green;
            margin-left: -15px;
        }

        & .document {
            h3 {
                font-size: 18px;
            }

            .search-field {
                display: flex;
                @media (max-width: 766px) {
                    display: block;
                }
            }
        }
    }
}
