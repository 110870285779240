.text-area {
    border: 1px solid #e8e8e8;
    padding: 2px 10px;
    border-radius: 0.3rem;
    color: #737a91;
    resize: none;
    width: 100%;

    &:focus {
        border: 1px solid #007bff;
        outline: none;
        transition: ease-in 500ms;
    }
}