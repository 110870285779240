@import "styles/variables";

button.verified,
button.pending {
  padding: 10px;
  border: transparent;
  outline: none;
  border-radius: 10px;
  font-weight: bold;
  width: 100px;
  font-size: 15px;
}
button.verified {
  background: rgba(245, 150, 37, 0.15);
  color: #f59625;
}

button.Approved {
  background: rgba(0, 176, 7, 0.15);
  color: rgb(56, 124, 87);
}
button.Uploaded {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}
button.InvoiceGen {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}
button.InvoiceP {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}

// checking.scss

.statement {
  .title {
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */

    letter-spacing: -0.02em;

    color: #737a91;
  }
  .data-table {
    border-radius: 0;
    border-top: none;

    table {
      .table-header {
        background-color: rgba(47, 128, 237, 0.1);
        th {
          font-size: 14px;
        }

        th:first-child,
        th:last-child {
          border-radius: 0;
        }
      }
      .table-data-row {
        background-color: #f9f9f9;
      }
    }
  }
  .accordion {
    width: 100%;
    margin-top: 40px;
    .contentBx {
      position: relative;

      .label {
        padding: 15px 5%;
        background: rgba($color: #2f80ed, $alpha: 0.2);

        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;

        .text {
          display: flex;
          justify-content: space-between;
          width: 80%;
          align-items: center;
          p {
            font-weight: bold;
            font-size: 16px;

            /* identical to box height, or 212% */

            letter-spacing: -0.02em;

            color: #000000;
          }
        }

        img {
          width: 15px;
          transform: rotateX(180deg);
          transition: 0.3s ease;
          transition: 0.3s ease;
        }
      }
      .content {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: 0.5s;
        // overflow-y: auto;
        //   @include webkit;
      }
      &.active {
        .label {
          img {
            transform: rotateX(0deg);
          }
        }
        .content {
          height: max-content;
        }
      }
    }
    &.red {
      margin-top: 0;
      .contentBx {
        .label {
          background-color: rgba($color: #f21212, $alpha: 0.2);
        }
        table {
          .table-header {
            background-color: rgba($color: #ed2f2f, $alpha: 0.4);
            box-shadow: 0px 1px 5px rgba(219, 219, 219, 0.1);
          }
          .table-data-row {
            background-color: #d67d82;
          }
        }
      }
    }
  }
}
