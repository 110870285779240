@import "styles/variables";

#score-or-view-modal {
    .user-score {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        :nth-child(2) {
            margin-left: 2rem;
        }

        .btn {
            border: 1px solid #dfe2e6;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 5px 25px;
        }
    }
    form {
        .question-box {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;

            .scoreField{

                background: #ffffff;
                border: 1px solid #dfe2e6;
                box-sizing: border-box;
                border-radius: 10px;
                resize: none;
                padding: 15px 20px;
                max-width: 100%;
                outline: none;
                margin-left: 0.9rem;

                &:focus-within {
                    border-color: #8d8bdd;
                }

                @media screen and (max-width: 769px) {
                    width: 100%;
                }

            }

            .question-label {
                font-family: "Averia-regular";
                font-size: 18px;
                line-height: 36px;
                letter-spacing: -0.02em;
                color: #737a91;
                position: relative;
                margin-bottom: 0.7rem;
            }
            .textarea {
                background: #ffffff;
                border: 1px solid #dfe2e6;
                box-sizing: border-box;
                border-radius: 10px;
                resize: none;
                padding: 15px 20px;
                max-width: 100%;
                outline: none;
                margin-left: 0.9rem;

                &:focus-within {
                    border-color: #8d8bdd;
                }

                @media screen and (max-width: 769px) {
                    width: 100%;
                }
            }
        }
    }
}
