@import "styles/variables";
.master-data {
  .cover_btn_change {
    align-items: center;
    width: 100%;
    justify-content: space-between;
    max-width: 100% !important;
    width: 100% !important;
  }
  .btn_change {
    margin-left: 10px;
    background-color: #2f80ed;
    padding: 6px 15px;
    height: unset;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .container {
    @media screen and (max-width: 769px) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  .cat_sub {
    display: flex;
    flex-direction: column;
    width: 100% !important;

    margin: auto !important;
    margin-top: 2.4rem !important;
  }
  .wider {
    .textbox {
      width: 400px;
    }
  }
  .tab-container {
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 0.625rem;
    min-height: 39rem;
    padding-left: 2rem;
    padding-right: 5rem;

    .tabs-page {
      margin-top: -1.5rem;
    }

    @media screen and (max-width: 769px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .button-position {
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 20px;
  }
  .button-container {
    display: flex;
  }

  .page-title {
    color: #3d59b7;
    font-size: 23px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 1rem;
    font-family: "Averia-bold";
  }
}
.hover-a:hover {
  color: #3d59b7;
}
