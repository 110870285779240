@import "styles/variables";

.tab-menu {
  margin-top: 20px;
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background: #f0f2f3;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 7px;
    background: #8198b1;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    background: #8198b1;
  }

  @media screen and (max-width: 769px) {
    margin-top: 10px;
  }
  position: relative;

  &::after {
    display: inline-block;
    content: "" "";
    width: 100%;
    height: 0.25em;
    position: absolute;
    bottom: 0.2em;
    left: 0;
    border-bottom: 1px solid $line;
  }
  &.set {
    &::after {
      display: unset;
      content: unset;
      width: unset;
      height: unset;
      position: unset;
      bottom: unset;
      left: unset;
      border-bottom: unset;
    }
    .item {
      &::after {
        display: inline-block;
        content: "";
        width: 100%;
        height: 0.25em;
        position: absolute;
        bottom: -0.9em;
        left: 0;
      }
      &--active {
        & > span {
          color: $yara-blue;
          // font-family: "GT Walsheim-medium";
          font-family: "Averia-regular";

          &::after {
            // border-bottom: 3px solid $yara-blue;
            border-bottom: unset !important;
            border-bottom-color: unset !important;
            // background-color: #67bbfb;
          }

          & > span {
            background-color: #92929d;
            // color: #3065cd;
            color: $green;
            font-family: "GT Walsheim-medium";
          }
        }
      }
    }
  }

  .item {
    display: inline-block;
    width: max-content;
    // padding: 0 5px;
    font-weight: 500;
    font-size: 1em;
    letter-spacing: 0.005em;
    margin: 0.3em 3em 1.14em 0;

    color: $dark-ash;
    position: relative;
    line-height: 30px;
    cursor: pointer;

    @media screen and (max-width: 769px) {
      margin-right: 0.7rem;
    }

    &::after {
      display: inline-block;
      content: "";
      width: 100%;
      height: 0.25em;
      position: absolute;
      bottom: -0.9em;
      left: 0;
    }

    &--active {
      & > span {
        color: $yara-blue;
        // font-family: "GT Walsheim-medium";
        font-family: "Averia-regular";

        &::after {
          border-bottom: 3px solid $yara-blue;
          // background-color: #67bbfb;
        }

        & > span {
          background-color: #92929d;
          // color: #3065cd;
          color: $green;
          font-family: "GT Walsheim-medium";
        }
      }
    }
  }
}
