.po-modal {
  .modal-container {
    .contentwrapper {
      width: calc(100% - 30rem);
      max-width: calc(100% - 5rem);
    }
  }
  #create-po-modal {
    .search-cont {
      width: 65%;
    }
    .terms-cont {
      background: rgba(47, 128, 237, 0.1);
      padding: 10px 15px;
      border-radius: 5px;
      column-gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .terms-select {
        font-size: 13px;
        border: 0;
        width: 6rem;
        &:focus {
          border: 0;
        }
      }
    }
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 8px;
      td {
        text-align: center;
        padding: 5px;
        border-bottom: solid 1px #dfe2e6;
        border-top: solid 1px #dfe2e6;
      }
      th {
        padding: 5px;
        text-align: center;
        //   color: #3d59b7;
        background-color: rgba(47, 128, 237, 0.1);
        line-height: 36px;
        font-weight: 700;
        font-family: "Averia-bold";
      }
      th:first-child {
        border-left: solid 1px #dfe2e6;
        border-radius: 10px 0px 0px 10px;
      }
      th:last-child {
        border-right: solid 1px #dfe2e6;
        border-radius: 0px 10px 10px 0px;
      }
      .first-td {
        border-left: solid 1px #dfe2e6;
        border-radius: 10px 0px 0px 10px;
        padding: 2rem;
      }
      .last-td {
        border-right: solid 1px #dfe2e6;
        border-radius: 0px 10px 10px 0px;
        padding: 2rem;
      }
    }
    .btm-btn {
      width: fit-content;
      padding-left: 4rem;
      padding-right: 4rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.terms-cont2{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .firstT{
    width: 47%;
    margin-bottom: 10px;
    @media(max-width: 770px){
      width: 100%;
    }
  }
}