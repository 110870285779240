#document-single {
  .container {
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }

    .stats-card {
      .stats-icon-red {
        background: rgba(255, 65, 65, 0.2);
      }
      //   .stats-icon-orange {
      //     background: rgba(242, 153, 74, 0.185);
      //   }
      .stats-icon-blue {
        background: rgba(45, 156, 219, 0.185);
      }

      .stats-icon-orange,
      .stats-icon-blue,
      .stats-icon-green {
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
          color: #ff7501;
        }
      }
    }

    & .document {
      h3 {
        font-size: 18px;
      }

      .search-field {
        display: flex;
        #btn-custom {
          border: 1px solid #dfe2e6;
          border-radius: 10px;
          background: #ffffff;
          border-radius: 10px;
          line-height: 30px;
          color: #737a91;
          padding: 10px 42px;
        }

        @media (max-width: 766px) {
          display: block;
        }
      }

      #upload {
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        background: #ffffff;
        border-radius: 10px;
        line-height: 30px;
        color: #737a91;
        padding: 6px 82px;
      }
    }
  }
  .page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;

    font-family: "Averia-bold";
  }
  .extra {
    .page-title {
      text-align: right;
    }
  }
  .border-container {
    border: 1px solid #dfe2e6;
    border-radius: 8px;
    padding: 5px;
  }
  .button-dupli {
    border: 1px solid #dfe2e6;
    padding: 5px 15px;
    border-radius: 8px;
    margin-left: 5px;
    align-self: center;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
    td {
      text-align: center;
      padding: 5px;
      border-bottom: solid 1px #dfe2e6;
      border-top: solid 1px #dfe2e6;
    }
    th {
      padding: 5px;
      text-align: center;
      color: #3d59b7;
      line-height: 36px;
      font-weight: 700;
      font-family: "Averia-bold";
      background-color: rgba(119, 157, 207, 0.1);
    }
    .first-td {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    .last-td {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
    }
    // tr {
    //   .border-container {
    //     border-bottom: solid 1px red;
    //     border-left: solid 1px red;
    //     border-radius: 5px 0px 0px 5px;
    //     border-top: solid 1px red;
    //   }
    // }
  }
  .newStyle {
    border: 1px solid #e8e8e8;
    padding: 0.1rem 2.5rem 0.6rem;
    border-radius: 0.6rem;
    border-top: solid 20px rgba(119, 157, 207, 0.1);
  }
}
