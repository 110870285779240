@import "styles/variables";
#settings-location-data {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .dropdown-style {
    right: -10px;
  }

  .edit_tag {
    color: $yara-blue;
  }

  .delete_tag {
    color: #eb5757;
  }
}
