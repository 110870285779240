#single-requisition {
  .container {
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }
    hr {
      width: 90%;
    }
    .details-container {
      display: flex;

      .first-col {
        width: 40%;

        .unit-info {
          margin-bottom: 0.7rem;

          h4 {
            color: #304ba3;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0.4rem;
          }
          p {
            width: 200px;
          }
        }
        #upload {
          border: 1px solid #dfe2e6;
          border-radius: 10px;
          background: #ffffff;
          border-radius: 10px;
          line-height: 30px;
          color: #737a91;
          padding: 6px 40px;
        }
      }
      .second-col {
        width: 60%;

        .unit-info {
          margin-bottom: 0.7rem;

          h4 {
            color: #304ba3;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0.4rem;
          }
          p {
            width: 200px;
          }
        }
        .upload {
          #upload {
            background: #f5f2f2;
            border: 1px solid #dfe2e6;
            box-sizing: border-box;
            border-radius: 10px;
            color: #737a91;
            padding: 6px 10px;
          }
        }
      }
    }
    .item-container {
      .item {
        .empty-item {
          margin-top: 5rem;
          h4 {
            font-weight: bold;
            font-size: 18px;
            line-height: 36px;
            letter-spacing: -0.02em;
            color: #737a91;
          }
          hr {
            margin: 0px;
          }
        }
        .purchase-table {
          width: 80%;
          display: flex;
          flex-direction: column;

          .table-header {
            th {
              margin-bottom: 3rem;
              padding: 0 1em;
              padding-bottom: 7px;
              text-align: left;
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
              color: #1d46ce;
            }
          }

          .table-data-row {
            background: #ffffff;
            margin-bottom: 1rem;
            border: 1px solid #dfe2e6;

            td {
              padding: 0.5em 1em;
              text-align: left;
              font-weight: normal;
              font-size: 12px;
              line-height: 30px;
            }
          }
        }
        .history {
          .status-flow {
            margin-top: 3rem;
            display: flex;

            .current-status {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-weight: normal;
              font-size: 12px;
              line-height: 30px;
              color: #000000;

              .gray-circle {
                position: relative;
                width: 30px;
                height: 30px;
                border: 1px solid #454545;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
              }
              #bigger-circle,
              .gray-circle {
                // width: 60px;
                // height: 60px;
                margin-top: 13px;
                margin-left: 2.5rem;
              }
              #bigger-circle {
                margin-left: 59px;
                margin-right: 75px;
              }
              #smaller-circle {
                margin-top: 13px;
              }
              .green-circle {
                position: relative;
                width: 34px;
                height: 34px;
                border: 1px solid #22a238;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;

                .rigid-circle {
                  background-color: #22a238;
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  z-index: 1;
                }

                .pipe {
                  position: absolute;
                  right: 0px;
                  width: 7px;
                  height: 3px;
                  background-color: #22a238;
                  display: inline;
                }

                .upward {
                  position: absolute;
                  right: -82.5px;
                  width: 82px;
                  height: 3px;
                  background-color: #22a238;
                }

                .upward-rev {
                  left: -84px;
                }
              }
            }
          }
        }
        h4 {
          font-weight: bold;
          font-size: 18px;
          line-height: 36px;
          letter-spacing: -0.02em;
          color: #737a91;
        }
        hr {
          margin: 0px;
        }
      }
    }
  }
}
