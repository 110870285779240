@import "styles/variables";
@import "react-quill/dist/quill.snow.css";
#sourcing {
  .container {
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }

    .stats-card {
      .stats-icon-blue {
        background: rgba(45, 156, 219, 0.185);
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
          color: #ff7501;
        }
      }
    }

    & .document {
      h3 {
        font-size: 18px;
      }

      .search-field {
        display: flex;
        @media (max-width: 766px) {
          display: block;
        }
      }

      #upload {
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        background: #ffffff;
        border-radius: 10px;
        line-height: 30px;
        color: #737a91;
        padding: 6px 82px;
      }
    }
  }
}
#settings-company {
  .container {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -1px;
  }

  .page-title {
    font-family: "Averia-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 18em / $scale;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .dropdown-style {
    right: -10px;
  }

  .edit_tag {
    color: $yara-blue;
  }

  .delete_tag {
    color: #eb5757;
  }
}
.edit_tag {
  color: $yara-blue;
}

.delete_tag {
  color: #eb5757;
}
