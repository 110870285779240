#prop2 {
  .status {
    // background: rgba(0, 70, 176, 0.15);
    // color: #2f80ed;
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
  .approved {
    background: rgba(0, 176, 80, 0.15);
    color: #00b050;
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
  .reject {
    background: rgba(245, 150, 37, 0.15);
    color: rgb(245, 150, 37);
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
  .posted {
    background: rgba(0, 70, 176, 0.15);
    color: #2f80ed;
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
  .draft {
    background: rgba(45, 156, 219, 0.1);
    color: rgb(47, 128, 237);
    padding: 6px 35px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
  }
}

.beside {
  width: 120px;
  margin-right: 10px;
  padding: 3.5px 12px !important;
  margin-left: 10px;
}
.btnBox5 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.coverme {
  display: flex;
  align-items: baseline;
  a {
    color: #fff;
    background-color: rgb(26, 109, 26);
    border: none;
    padding: 5px 12px;
    border-radius: 5px;
    margin: 0 10px;
    text-decoration: none;
    display: block;
    &:hover {
      color: #fff;
      background-color: rgb(26, 109, 26);
    }
  }
}
