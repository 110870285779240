#invoice {
  .container {
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }

    .stats-card {
      .stats-icon-green {
        background: rgba(0, 176, 79, 0.185);
      }
      .stats-icon-orange {
        background: rgba(242, 153, 74, 0.185);
      }
      .stats-icon-red {
        background: rgba(255, 65, 65, 0.185);
      }

      .stats-icon-orange,
      .stats-icon-red,
      .stats-icon-green {
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
          color: #ff7501;
        }
      }
    }

    & .document {
      h3 {
        font-size: 18px;
      }

      .search-field {
        display: flex;
        @media (max-width: 766px) {
          display: block;
        }
      }

      #upload {
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        background: #ffffff;
        border-radius: 10px;
        line-height: 30px;
        color: #737a91;
        padding: 6px 82px;
      }
    }
  }
}

.cover_hover {
  position: relative;
  cursor: pointer;
  svg {
    margin: auto;
    display: block;
  }
  .error_real {
    display: none;
    position: absolute;
    top: -160px;
    left: -120%;
    width: 300px;
    min-height: 150px;
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:hover {
    .error_real {
      display: block;
    }
  }
}

.status {
  // background: rgba(0, 70, 176, 0.15);
  // color: #2f80ed;
  padding: 6px 35px;
  border-radius: 8px;
  text-align: center;
  width: fit-content;
}

.approved {
  background: rgba(0, 176, 80, 0.15);
  color: #00b050;
}
.reject {
  background: rgba(248, 90, 90, 0.15);
  color: #f85a5a;
}
.posted {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}
.draft {
  background: rgba(245, 150, 37, 0.15);
  color: #f59625;
}
.paid {
  background: #0f630e;
  color: #ffffff;
}
.pending {
  background: #efc442;
  color: #2a2a2a;
}
