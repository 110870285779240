//--------------------------------------------------------------------
//Typography
//--------------------------------------------------------------------

@font-face {
    font-family: "GT Walsheim-regular";
    src: url(../assests/fonts/GTWalsheimPro-Regular.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Regular.woff) format("woff");
}
@font-face {
    font-family: "GT Walsheim-light";
    src: url(../assests/fonts/GTWalsheimPro-Light.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Light.woff) format("woff");
}
@font-face {
    font-family: "GT Walsheim-thin";
    src: url(../assests/fonts/GTWalsheimPro-Thin.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Thin.woff) format("woff");
}
@font-face {
    font-family: "GT Walsheim-bold";
    src: url(../assests/fonts/GTWalsheimPro-Bold.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Bold.woff) format("woff");
}
@font-face {
    font-family: "GT Walsheim-medium";
    src: url(../assests/fonts/GTWalsheimPro-Medium.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Medium.woff) format("woff");
}
@font-face {
    font-family: "GT Walsheim-medium";
    src: url(../assests/fonts/GTWalsheimPro-Medium.ttf) format("truetype"),
        url(../assests/fonts/GTWalsheimPro-Medium.woff) format("woff");
}


//YARA FONTS

@font-face {
    font-family: "Candal";
    src: url(../assests/fonts/Candal-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Averia-regular";
    src: url(../assests/fonts/AveriaLibre-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "Averia-light";
    src: url(../assests/fonts/AveriaLibre-Light.ttf) format("truetype");
}
@font-face {
    font-family: "Averia-bold";
    src: url(../assests/fonts/AveriaLibre-Bold.ttf) format("truetype");
}
