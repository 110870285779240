@import "styles/variables";
#accordion {
  .container {
    padding: 12px 3.5rem 12px 1rem;
    margin-bottom: 1px;
    background: rgba(47, 128, 237, 0.2);
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    // ul {
    //   justify-content: space-between;
    //   display: inline-flex;
    //   flex-grow: 1;
    //   margin-bottom: 0;
    //   color: #000000;
    //   font-weight: bold;
    //   span {
    //     color: #000000;
    //     font-family: "Averia-light";
    //     font-weight: 100;
    //   }
    // }
    .icon {
      flex-grow: 1;
      justify-content: end;
    }
    .items {
      // flex-grow: 5;
      width: 95%;
      align-items: center;
      .first {
        width: 85%;
        // justify-content: space-between;
        display: flex;
        align-items: center;
        .row {
          span {
            color: #000000;
            font-family: "Averia-light";
            font-weight: 100;
          }
          .first {
            font-weight: bolder;
            color: #000000;
            font-family: "Averia-bold";
            span {
              color: #000000;
              font-family: "Averia-light";
              font-weight: 100;
            }
          }
          .second {
            color: #000000;
            font-family: "Averia-light";
            font-weight: 100;
            span {
              font-weight: bolder;
              color: #000000;
              font-family: "Averia-bold";
            }
          }
        }
      }
      .second {
        margin-left: auto;
      }
    }
    .check-head {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: white;
      display: flex;
      place-items: center;
      justify-content: center;

      &.active {
        & svg {
          fill: rgba(47, 128, 237, 0.2) !important;
          & path {
            fill: rgba(47, 128, 237, 0.2) !important;
            // stroke: $yara-blue !important;
          }
        }
      }
    }
  }
  table {
    .table-header {
      box-shadow: 0px 1px 5px rgba(219, 219, 219, 0.1);
      background-color: rgba(47, 128, 237, 0.1);
      color: #000000;
      font-weight: 100;
      font-family: "Averia-light";
    }
    th {
      font-weight: 100;
      font-size: 15px;
    }
    th:first-child {
      padding: 0.7rem;
      //   width: 6rem;
      // padding-left: 4rem;
      text-align: center;
      //   padding-left: 4rem;
    }
    th:last-child {
      padding: 0.7rem;
      padding-right: 2rem;
      width: 12%;
      text-align: center;
    }
    td {
      background-color: rgba(249, 249, 249, 1);
    }
    td:first-child {
      padding: 1rem;
      // padding-left: 4rem;
      background-color: rgba(249, 249, 249, 1);
      display: flex;
      align-items: center;
    }
    td:last-child {
      padding: 1rem;
      padding-right: 2rem;
      background-color: rgba(249, 249, 249, 1);
      text-align: center;
    }
  }
  .trans {
    transition: all 0.5s ease-in-out;
  }
  .debit {
    td {
      background-color: rgba(248, 90, 90, 0.3) !important;
      color: black;
    }
  }
  @media screen and (max-width: 969px) {
    .accordion {
      overflow-x: scroll;
      .accordion-item {
        width: max-content;
      }
    }
  }
}
