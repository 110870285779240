@import "styles/variables";

#single-contract-page {
  .container {
    @media screen and (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .page-title {
    font-family: "Averia-bold";
    font-style: normal;
    font-weight: bold;
    font-size: 1.125em;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #304fb7;
    // font-size: 18em / $scale;

    @media screen and (max-width: 768px) {
      font-size: 1.125em;
    }
  }
  .extra {
    .page-title {
      text-align: right;
    }
  }

  .stats-icon-cash {
    background: $cash;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: #ff7501;
    }
  }
  .stats-icon-leaf {
    background: $leaf;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-valid {
    background: $valid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }
  .stats-icon-invalid {
    background: $invalid;
    border-radius: 0px 10px;
    padding: 20px;

    .icon-image {
      color: white;
    }
  }

  .purchase_order {
    margin-top: 5.5rem;

    &_heading {
      position: relative;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 0.5rem;
      }

      &::after {
        display: inline-block;
        content: "" "";
        width: 100%;
        height: 0.25em;
        position: absolute;
        bottom: -0.5em;
        left: 0;
        border-bottom: 1px solid $line;
      }
      .btn-padding {
        padding: 0.375rem 1.5rem;
      }
    }

    .purchase-details {
      &_list {
        gap: 1.8em;

        .text-lengthy {
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .yara-color {
        color: #304fb7;
      }
    }

    .purchase-table {
      @media screen and (max-width: 1199px) {
        overflow-x: scroll;
      }

      @media screen and (max-width: 969px) {
        width: 90vw;
        overflow-x: scroll;
      }
      // .table-header {
      //     th {
      //         margin-bottom: 3rem;
      //         padding: 0 1em;
      //         color: #304fb7;
      //         padding-bottom: 7px;
      //         text-align: left;
      //     }
      // }

      // .table-data-row {
      //     background: #ffffff;
      //     border: 1px solid #dfe2e6;
      //     border-radius: 10px;

      //     td {
      //         padding: 0.5em 1em;
      //         text-align: left;
      //     }
      // }
    }
  }

  .transaction_history {
    .lead-text {
      font-family: "Averia-bold";
      font-style: normal;
      font-weight: bold;
      font-size: 18em / $scale;
      line-height: 36px;
      letter-spacing: -0.02em;
      position: relative;

      &::after {
        display: inline-block;
        content: "" "";
        width: 100%;
        height: 0.25em;
        position: absolute;
        bottom: -0.3em;
        left: 0;
        border-bottom: 1px solid $line;
      }
    }

    .transaction_box {
      background: #ffffff;
      border: 1px solid #dfe2e6;
      box-sizing: border-box;
      border-radius: 10px;
      justify-content: space-between;
      padding: 0.5rem 0.7rem;
      width: 75%;

      @media screen and (max-width: 969px) {
        width: 100%;
        gap: 1rem;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        gap: 0.5rem;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
    td {
      text-align: center;
      padding: 5px;
      border-bottom: solid 1px #dfe2e6;
      border-top: solid 1px #dfe2e6;
    }
    th {
      padding: 5px;
      text-align: center;
      color: #3d59b7;
      line-height: 36px;
      background-color: rgba(119, 157, 207, 0.1);
      font-weight: 700;
      font-family: "Averia-bold";
    }
    .first-td {
      border-left: solid 1px #dfe2e6;
      border-radius: 10px 0px 0px 10px;
    }
    .last-td {
      border-right: solid 1px #dfe2e6;
      border-radius: 0px 10px 10px 0px;
    }
    // tr {
    //   .border-container {
    //     border-bottom: solid 1px red;
    //     border-left: solid 1px red;
    //     border-radius: 5px 0px 0px 5px;
    //     border-top: solid 1px red;
    //   }
    // }
  }
  .newStyle {
    border: 1px solid #e8e8e8;
    padding: 0.1rem 2.5rem 0.6rem;
    border-radius: 0.6rem;
    border-top: solid 20px rgba(119, 157, 207, 0.1);
  }
}
