@import "styles/variables";

#company-docs-page {
    .container {
        margin-right: 0px;
        margin-left: 0px;
        max-width: 100%;
    }
    .page-title {
        font-family: "Averia-bold";
        font-style: normal;
        font-weight: bold;
        font-size: 18em / $scale;
        line-height: 36px;
        letter-spacing: -0.02em;
    }

    .span-container {
        display: flex;

        .edit {
            border-right: 1px solid #737a91;
            padding-right: 10px;
            width: fit-content;
            color: $green;

            cursor: pointer;
        }
        .delete {
            padding-right: 10px;
            width: fit-content;
            color: #737a91;
            cursor: pointer;
        }
    }

    .middle-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        .icon-image {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 6px;
        }
        h5 {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
        }
    }

    .stats-expiring {
        background: $contract !important;
        border-radius: 0px 10px;
        padding: 20px;
    }

    .button-position {
        width: auto;
        display: flex;
        justify-content: center;
        padding: 6px 20px;
    }
    .button-container {
        display: flex;
    }
    .arrow-div {
        @media screen and (max-width: 769px) {
        }
    }
    .box-scroll {
        max-height: 17rem;
        overflow-y: scroll;
    }
}

.hover-a:hover {
    color: #3d59b7;
  }
  