@import "styles/variables";

.select-dropdown {
  position: relative;
  width: 100%;

  &:focus-within fieldset {
    border: 1px solid $yara-blue;
    transition: ease-in 500ms;
  }
  .error {
    border: 1px solid #d34242;
  }
  .label-error {
    color: #d34242;
  }
  &:focus-within legend {
    color: $ash;
    transition: ease-in-out 500ms;
  }
  fieldset {
    min-width: 0;
    padding: 10px;
    margin: 0;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    outline: none;
  }
  legend {
    max-width: fit-content;
    font-size: 1.5rem;
    line-height: inherit;
    color: #e5e5e5;
    white-space: normal;
    text-align: left;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: -5px;
  }

  .input-error {
    text-align: left;
    font-size: 12px;
    color: #d34242 !important;
  }

  .scroll {
    overflow-y: scroll;
    max-height: 10rem;
  }

  .select-role {
    color: $light-black !important;
  }

  & .select-box {
    background: #ffffff;
    // border: 1px solid #e8e8e8;
    // box-sizing: border-box;
    // border-radius: 10px;
    padding: 0rem 0.5rem;
    display: flex;
    justify-content: space-between;

    input {
      width: 100%;
      height: auto;
      outline: none;
      border: 0;
      background: transparent;
      color: $black;
      &::placeholder {
        color: $black;
      }
    }
    p {
      font-family: "GT Walsheim-regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $ash;
    }
    @media (max-width: 767px) {
      max-width: 100%;
      padding-bottom: 20px;
    }
  }

  & .select-options {
    background: #ffffff;
    border: 0.5px solid $line;
    box-sizing: border-box;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    overflow: auto;
    z-index: 2;
    position: absolute;
    // width: 100%;
    width: 96%;

    & .options-dropdown {
      margin-top: 10px;
      margin-bottom: 10px;

      &.label {
        overflow-y: scroll;
        height: 290px;
      }

      li {
        padding: 5px 0px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;

        p {
          color: $ash;
        }

        &:hover {
          background-color: $yara-blue-opacity;
        }
      }
    }
    .search {
      padding: 5px 10px 0px;
      border: solid 1px #737a91;
      &:focus-within {
        outline: #737a91;
      }
      border-radius: 5px;
    }
  }
}

// #btn-browse {
//   border: 1px solid #e8e8e8;
//   padding: 2px 10px;
//   border-radius: 0.3rem;
//   color: #737a91;
// }
