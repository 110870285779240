@import "styles/variables";
.verify-file-modal {
  .role-p {
    text-align: center;
    position: relative;
    bottom: 1rem;
    cursor: pointer;
  }

  .role-modal-top {
    top: -120px;
  }
  .yaraa-color {
    color: $iris;
  }
  .error {
    text-align: left;
    font-size: 13px;
    color: #d34242 !important;
  }
}
