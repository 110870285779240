@import "styles/variables";
.show-div {
    padding: 5px 0;
    margin-left: auto;
    p {
        // margin-top: auto;
        // margin-bottom: auto;
        padding-top: 3px;
    }
    .page-select {
        background: white;
        padding-top: 3px;
        border-radius: 5px;
        .menu-select {
            width: inherit;
            padding: 0 20px;
            text-align: center;
            transition: all 0.2s;
        }
        .menu-select:hover {
            background-color: #d5e6fb;
            cursor: pointer;
        }
    }
}
