//--------------------------------------------------------------------
//Variables icon bacground
//--------------------------------------------------------------------
$leaf: rgba(242, 153, 74, 0.1);
$cash: rgba(45, 156, 219, 0.1);
$valid: rgba(0, 176, 80, 0.1);
$invalid: rgba(255, 65, 65, 0.1);
$purple: rgba(187, 107, 217, 0.1);
$orange: rgba(255, 117, 0, 0.1);
$folder: rgba(255, 75, 109, 0.1);
$icon-black: rgba(80, 85, 102, 0.1);
$lga: rgba(68, 106, 251, 0.1);
$state: rgba(22, 39, 103, 0.1);
$employ: rgba(1, 77, 35, 0.1);
$community: rgba(242, 153, 74, 0.1);

//Yara Icon Varibles
$yara-blue-opacity: rgba(47, 128, 237, 0.2);
$contract: rgba(0, 133, 255, 0.1);


//--------------------------------------------------------------------
//Variables
//--------------------------------------------------------------------
$green: #00b050;
$light-green: #cbeedb;
$red: #c82c20;

$light-black: #333333;
$white: #f9fcf9;
$black: #000000;
$error: #f54541;
$line: #e8e8e8;
$dark-ash: #505566;
$yellowish: #f59625;

//Yara variables
$lavender: #e6ebf6;
$purpish: #340e73;
$yara-red: #f10101;
$yara-blue: #2f80ed;
$firebrick: #bc1b1b;
$yara-lime: #00b0a5;
$yara-blueish: #2e4593;
$ash: #737a91;
$iris: #5D5FEF;

$scale: 16;
