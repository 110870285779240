#document {
  .container {
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }
    .middle-img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      .icon-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
      }
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
      }
    }

    .stats-card {
      .stats-icon-red {
        background: rgba(255, 65, 65, 0.2);
      }
      //   .stats-icon-orange {
      //     background: rgba(242, 153, 74, 0.185);
      //   }
      .stats-icon-blue {
        background: rgba(45, 156, 219, 0.185);
      }

      .stats-icon-orange,
      .stats-icon-blue,
      .stats-icon-green {
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
          color: #ff7501;
        }
      }
    }

    & .document {
      h3 {
        font-size: 18px;
      }

      .search-field {
        display: flex;
        align-items: center;
        #btn-custom {
          border: 1px solid #dfe2e6;
          border-radius: 10px;
          background: #ffffff;
          border-radius: 10px;
          line-height: 30px;
          color: #737a91;
          padding: 10px 42px;
        }

        @media (max-width: 766px) {
          display: block;
        }
      }

      #upload {
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        background: #ffffff;
        border-radius: 10px;
        line-height: 30px;
        color: #737a91;
        padding: 6px 82px;
      }
    }
  }
  .page-title {
    color: #3d59b7;
    line-height: 36px;
    font-weight: 700;
    font-family: "Averia-bold";
  }
}
