@import "styles/variables";

.fileUploadBox {
    .btn-browse {
        background: #e8e8e8;
        border: 1px solid #dfe2e6;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 0.2rem 2.2rem;
        color: #747482;
        font-weight: bold;
        font-size: 18px;
        line-height: 36px;
    }
    &:focus {
        outline: none;
    }
    &:focus-within fieldset {
        border: 1px solid $yara-blue;
        transition: ease-in 500ms;
    }

    .disabled-input legend {
        color: $ash;
    }
    .disabled-input {
        border: 1px solid #d5d5d5;
    }
    .error {
        border: 1px solid #d34242;
        background-color: transparent !important;
    }
    .label-error {
        color: #d34242;
    }
    &:focus-within legend {
        color: $ash;
        transition: ease-in-out 500ms;
    }
    fieldset {
        min-width: 0;
        padding: 2px 5px 5px;
        margin: 0;
        border: 1px solid #dfe2e6;
        border-radius: 8px;
        outline: none;
    }
    legend {
        max-width: fit-content;
        line-height: 20px;
        color: $ash;
        white-space: normal;
        text-align: left;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: -5px;
        font-weight: 400;
    }
    input {
        width: 100%;
        height: auto;
        outline: none;
        border: 0;
        background: transparent;

        &::placeholder {
            color: $ash;
        }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .input-error {
        text-align: left;
        font-size: 12px;
        color: #d34242 !important;
    }
    .a-tag {
        text-align: left;
        font-size: 12px;
        color: #2f80ed !important;
    }
}
