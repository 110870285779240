button {
    background-color: transparent;
    border: none;
    outline: none;
}
.on {
    color: gold;
}
.off {
    color: #ccc;
}
.star {
    font-size: 1.1rem;
}
.star-rating {
    display: flex;
    white-space: nowrap;
    margin-left: 0.5rem;
    gap: 0.3rem;
}
