@import "styles/variables";

.catalogue {
    // max-width: 100%;
    // overflow: hidden;

    &__content {
        // padding: 1rem 2rem;
        // background: #fdfdfd;
        // box-sizing: border-box;

        & header {
            // display: flex;

            h4 {
                font-size: 17px;
                font-family: "Averia-bold";
                font-style: normal;
                font-weight: bold;
                font-size: 1.125em;
                line-height: 36px;
                letter-spacing: -0.02em;
            }
        }

        .middle-img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            .icon-image {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
            }
            h5 {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 30px;
            }
        }

        .stats-icon-orange {
            background: $contract;
            border-radius: 0px 10px;
            padding: 20px;
        }

        .stats-icon-leaf {
            background: $community;
            border-radius: 0px 10px;
            padding: 20px;
        }
        & .document {
            h3 {
                font-size: 18px;
            }
        }
    }
}
