@import "styles/variables";

.select-dropdown {
    &:focus-within fieldset {
        border: 1px solid $yara-blue;
        transition: ease-in 500ms;
    }
    .error {
        border: 1px solid #d34242;
    }
    .label-error {
        color: #d34242;
    }
    &:focus-within legend {
        color: $ash;
        transition: ease-in-out 500ms;
    }
    fieldset {
        min-width: 0;
        padding: 10px;
        margin: 0;
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        outline: none;
    }
    legend {
        max-width: fit-content;
        font-size: 1.5rem;
        line-height: inherit;
        color: #e5e5e5;
        white-space: normal;
        text-align: left;
        font-size: 12px;
        margin-left: 5px;
        margin-bottom: -5px;
    }
    select {
        width: 100%;
        height: auto;
        outline: none;
        border: 0;
        background: transparent;
    }
    .input-error {
        text-align: left;
        font-size: 12px;
        color: #d34242 !important;
    }
}
.additionalClass{
    width: 30% !important;
    @media (max-width: 766px) {
       width: 87% !important;
       margin-bottom: 20px;
    }
}