@import "styles/variables";

#budget {
  .container {
    .actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      button {
        background: #2f80ed;
        border-radius: 8px;
        display: flex;
        padding: 4px 10px;
        align-items: center;
        font-weight: 300;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: white;
        &.delete {
          background: #f85a5a;
          margin-left: 15px;
          img {
            margin-left: 5px;
          }
        }
      }
    }
    .status {
      p {
        font-weight: bold;
        font-size: 19.2644px;
        line-height: 39px;
        letter-spacing: -0.02em;
        margin-bottom: 10px;
      }
      button {
        background: rgba(0, 70, 176, 0.15);
        border-radius: 11.7202px;
        width: 140px;
        font-weight: bold;
        font-size: 17.5346px;
        color: #2f80ed;
        line-height: 21px;
        padding: 7px 0;
      }
    }
    .header {
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #304ba3;
    }

    .stats-card {
      .stats-icon-green {
        background: rgba(0, 176, 79, 0.185);
      }
      .stats-icon-orange {
        background: rgba(242, 153, 74, 0.185);
      }
      .stats-icon-blue {
        background: rgba(45, 156, 219, 0.185);
      }

      .stats-icon-purple,
      .stats-icon-blue,
      .stats-icon-green {
        border-radius: 0px 10px;
        padding: 20px;

        .icon-image {
          color: #ff7501;
        }
      }
    }

    & .document {
      h3 {
        font-size: 18px;
      }

      .search-field {
        display: flex;
        @media (max-width: 766px) {
          display: block;
        }
        .custom-select {
          background: #ffffff;
          border: 1px solid #e8e8e8;
          box-sizing: border-box;
          border-radius: 10px;
          width: fit-content;
          padding: 0.9rem 1.7rem;
          padding-bottom: 2.2rem;
        }
      }

      #upload {
        border: 1px solid #dfe2e6;
        border-radius: 10px;
        background: #ffffff;
        border-radius: 10px;
        line-height: 30px;
        color: #737a91;
        padding: 6px 82px;
      }
    }

    .activate_tag {
      color: $green;
    }

    .delete_tag {
      color: #eb5757;
    }
  }
}

button.select {
  background: #2f80ed;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;
  margin-top: 24px;
  height: 54px;
  width: 334px;
}

.bank_proposal {
  .accordion {
    width: 100%;
    margin-top: 40px;
    .contentBx {
      position: relative;

      .label {
        padding: 15px 5%;
        background: rgba($color: #2f80ed, $alpha: 0.2);

        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;

        .text {
          display: flex;
          justify-content: space-between;
          width: 80%;
          align-items: center;
          p {
            font-weight: bold;
            font-size: 16px;

            /* identical to box height, or 212% */

            letter-spacing: -0.02em;

            color: #000000;
          }
        }

        img {
          width: 15px;
          transform: rotateX(180deg);
          transition: 0.3s ease;
          transition: 0.3s ease;
        }
      }
      .content {
        position: relative;
        height: 0;
        overflow: hidden;
        transition: 0.5s;
        // overflow-y: auto;
        //   @include webkit;
      }
      &.active {
        .label {
          img {
            transform: rotateX(0deg);
          }
        }
        .content {
          height: max-content;
        }
      }
    }
    &.red {
      margin-top: 0;
      .contentBx {
        .label {
          background-color: rgba($color: #f21212, $alpha: 0.2);
        }
        table {
          .table-header {
            background-color: rgba($color: #ed2f2f, $alpha: 0.4);
            box-shadow: 0px 1px 5px rgba(219, 219, 219, 0.1);
          }
          .table-data-row {
            background-color: #d67d82;
          }
        }
      }
    }
  }
}
.bank2 {
  .data-table {
    overflow-x: auto;
    input {
    }
  }
}

.btnBox5 {
  button {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
    background: #2f80ed;
    border-radius: 10px;
    color: white;
    width: 300px;
    display: block;
    margin: auto;
    margin-top: 30px;
    padding: 10px 0;
  }
}
.status_ {
  // background: rgba(0, 70, 176, 0.15);
  // color: #2f80ed;
  padding: 6px 35px;
  border-radius: 8px;
  text-align: center;
  width: fit-content;
}

.approved {
  background: rgba(0, 176, 80, 0.15);
  color: #00b050;
}
.reject {
  background: rgba(248, 90, 90, 0.15);
  color: #f85a5a;
}
.posted {
  background: rgba(0, 70, 176, 0.15);
  color: #2f80ed;
}
.draft {
  background: rgba(245, 150, 37, 0.15);
  color: #f59625;
}
.paid {
  background: #0f630e;
  color: #ffffff;
}
.pending {
  background: #efc442;
  color: #2a2a2a;
}
