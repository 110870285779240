@import "styles/variables";

.buyer-side-bar {
  min-width: 220px;
  max-width: 220px;

  top: 0;
  left: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  z-index: 100;
  transition: left 0.4s;

  overflow-y: auto;
  padding-top: 0px;

  .side-bar-navigation {
    width: 208px;

    // height: 100%;
    // overflow-y: auto;

    .mbtn-auto {
      margin: 0 20px;
    }

    @media screen and (max-width: 969px) {
      position: relative;
      width: 100%;
    }

    .brand-logo {
      // margin-top: 10px;
      @media screen and (max-width: 969px) {
        display: flex;
        justify-content: space-evenly;
      }

      .close-menu {
        display: none;
        @media screen and (max-width: 969px) {
          display: block;
        }
      }
    }
    .fixedDiv {
      height: 75vh;
      @media screen and (min-height: 900px) {
        height: max-content;
      }

      overflow-y: auto;
      padding-bottom: 20px;
      position: fixed;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

        background: #f0f2f3;
      }

      &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
        background: #8198b1;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

        background: #8198b1;
      }
    }

    .nav-link {
      position: relative;
      margin-bottom: 0.175rem;
      width: 100%;
      &:first-child {
        padding-top: 0;
      }

      &.active {
        background-color: $yara-blue-opacity;
        border-radius: 5px;
        // padding: 0.7rem 1.5rem;
        // padding: 0.7rem 1rem;
        color: $yara-blue;
        // color: red;
        // font-weight: 500;
        transition: all ease-out;
        // font-family: "Averia-bold";

        @media screen and (max-width: 969px) {
          border-radius: 0px;
          color: $yara-blue !important;
        }

        & svg {
          fill: $yara-blue !important;
          & path {
            fill: $yara-blue !important;
            // stroke: $yara-blue !important;
          }
          & g {
            & rect {
              fill: $yara-blue !important;
              // stroke: $yara-blue !important;
            }
          }
        }
      }

      .route-name {
        font-style: normal;
        font-weight: normal;
        font-size: 18em / $scale;
        line-height: 36px;
        letter-spacing: -0.05em;
      }
    }
  }
  li {
    padding: 3px 5px;
    // text-align: center;
    padding-left: 56px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    margin-bottom: 3px;
    &.active {
      background-color: $yara-blue-opacity;
    }
    &:hover {
      background-color: $yara-blue-opacity;
    }
  }
  ul {
    transition: 0.5s ease-in-out;
  }

  @media screen and (max-width: 969px) {
    width: auto;
    position: fixed;
    left: -300px;
    height: 100%;
    background: #b5d4ff;

    &.hide-left {
      left: -300px;
    }

    &.show-left {
      left: 0;
    }
  }
}

// .side-bar-navigation::-webkit-scrollbar {
//     width: 4px;
// }

// .side-bar-navigation::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

// .side-bar-navigation::-webkit-scrollbar-thumb {
//     background: rgba(52, 14, 115, 1);
// }

// .side-bar-navigation::-webkit-scrollbar-thumb:hover {
//     background-color: #f2f2f2;
// }
