@import "styles/variables";

.tabs-small-comp {
    margin-top: 0.5rem;

    @media screen and (max-width: 769px) {
        margin-top: 10px;
    }
    position: relative;

    .item {
        display: inline-block;
        width: max-content;
        // padding: 0 5px;
        font-weight: 500;
        font-size: 1em;
        letter-spacing: 0.005em;
        // margin: 0.3em 3em 1.14em 0;
        color: $dark-ash;
        position: relative;
        line-height: 30px;
        cursor: pointer;

        @media screen and (max-width: 769px) {
            margin-right: 0.7rem;
        }

        &--active {
            color: $yara-blue;
            font-family: "Averia-regular";
        }
    }
}
